import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueTheMask from 'vue-the-mask'
import VueClipboard from 'vue-clipboard2'
import Print from 'vue-print-nb'
import VueQRCodeComponent from 'vue-qr-generator'

Vue.config.productionTip = false
VueClipboard.config.autoSetContainer = true
Vue.component('qr-code', VueQRCodeComponent)
Vue.use(VueClipboard)
Vue.use(Print);
Vue.use(VueTheMask)
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
