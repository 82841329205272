import {
    mdiHomeOutline,
    mdiMapOutline,
    mdiCogs,
    mdiAccountCircleOutline,
    mdiWeb,
    mdiAccountOutline,
    mdiFileDocumentOutline
} from '@mdi/js'

export default [
    {
        icon: mdiHomeOutline,
        title: 'หน้าแรก',
        to: 'administrator-main',
    },
    {
        icon: mdiAccountCircleOutline,
        title: 'ข้อมูลลูกค้า',
        to: 'customer',
    },
    {
        title: 'Administrator',
        icon: mdiAccountOutline,
        children: [
            {
                title: 'จัดการร้านค้า',
                to: 'administrator-shop',
            },
            {
                title: 'ผู้ดูแลระบบประจำร้าน',
                to: 'administrator-users',
            },
        ],
    },
    {
        title: 'จัดการหน้าเว็บไซต์',
        icon: mdiWeb,
        children: [
            {
                title: 'รูปภาพสไลด์',
                to: 'administrator-sliders',
            },
            {
                title: 'โปรโมชั่น',
                to: 'administrator-promotions',
            },
        ],
    },
    {
        title: 'รายงาน',
        icon: mdiFileDocumentOutline,
        children: [
            {
                title: 'รายงานสรุปยอดขาย',
                to: 'administrator-report-sale',
            },
        ],
    },
    {
        icon: mdiCogs,
        title: 'ตั้งค่า',
        to: 'administrator-setting',
    },

]
