function beforeEnter(to, from, next) {
  const token = localStorage.getItem('token')
  const userData = localStorage.getItem('userData')
  const roleName = localStorage.getItem('userRole')
  if (token && userData) {
    if (roleName === 'admin') {
      return next()
    } else {
      return next({ name: 'error-404' })
    }
  }


  return next({ name: 'auth-login' })
}

export default beforeEnter
