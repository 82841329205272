import beforeEnter from '@/router/checkLoginCenter'

const administrator = [
  {
    path: '/center/product',
    name: 'center-product',
    component: () => import('@/views/center/product/Products'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/center/customer',
    name: 'center-customer',
    component: () => import('@/views/center/customer/Customers'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/center/category',
    name: 'center-category',
    component: () => import('@/views/center/productCategories/ProductCategories'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
  {
    path: '/center/vat',
    name: 'center-vat',
    component: () => import('@/views/center/vat/Vat'),
    meta: {
      layout: 'content',
    },
    beforeEnter,
  },
]
export default administrator
