<template>
  <v-menu
      offset-y
      left
      nudge-bottom='14'
      min-width='230'
      content-class='user-profile-menu-content'
  >
    <template v-slot:activator='{ on, attrs }'>
      <v-badge
          bottom
          color='success'
          overlap
          offset-x='12'
          offset-y='12'
          class='ms-4'
          dot
      >
        <v-avatar
            size='40px'
            color='primary'
            class='v-avatar-light-bg primary--text'
            v-bind='attrs'
            v-on='on'
        >
          <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class='pb-3 pt-2'>
        <v-badge
            bottom
            color='success'
            overlap
            offset-x='12'
            offset-y='12'
            class='ms-4'
            dot
        >
          <v-avatar
              size='40px'
              color='primary'
              class='v-avatar-light-bg primary--text'
          >
            <v-img :src="require('@/assets/images/avatars/1.png')"></v-img>
          </v-avatar>
        </v-badge>
        <div
            class='d-inline-flex flex-column justify-center ms-3'
            style='vertical-align:middle'
        >
          <span class='text--primary font-weight-semibold mb-n1'>
            {{ userData.username }}
          </span>
          <small class='text--disabled text-capitalize'>{{ userData.role_name }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item to="/profile">
        <v-list-item-icon class='me-2'>
          <v-icon size='22'>
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>ตั้งค่าโปรไฟล์</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item :to="{name:'manager-setting'}" v-if="userData.role_name =='manager'">
        <v-list-item-icon class='me-2'>
          <v-icon size='22'>
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>ตั้งค่าร้านค้า</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class='my-2'></v-divider>

      <!-- Logout -->
      <v-list-item @click='logout()'>
        <v-list-item-icon class='me-2'>
          <v-icon size='22'>
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>ออกจากระบบ</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js'
import { useRouter } from '@core/utils'

import { ref, watch, onMounted } from '@vue/composition-api'
import store from '@/store'
import Swal from 'sweetalert2'
import { api } from "@/services/api"

export default {
  setup() {
    const { router } = useRouter()
    const shop = ref(null)
    const userData = ref({})


    const logout = () => {
      localStorage.removeItem('token')
      localStorage.removeItem('userData')
      localStorage.removeItem('userRole')
      localStorage.removeItem('shop')
      localStorage.removeItem('currencyId')
      localStorage.removeItem('menu')
      localStorage.removeItem('currency')
      router.push({ name: 'auth-login' })
    }

    userData.value = JSON.parse(localStorage.getItem('userData'))
    watch(
        () => store.state.app.isShow,
        () => {
          alertMessage()
        },
    )
    const alertMessage = () => {
      Swal.fire({
        position: 'top-end',
        icon: store.state.app.color,
        width: 350,
        toast: true,
        color: '#ffffff',
        iconColor: '#ffffff',
        background:
            store.state.app.color === 'success' ? '#56ca00' : store.state.app.color === 'warning' ? '#ffb400' : '#ff0000',
        title: store.state.app.message,
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 2000,
      })
    }

    onMounted(() => {
      getShop()
    })

    const getShop = async () => {
      api.get({
        path: '/shop',
      }).then(({ data }) => {
        localStorage.setItem('shop', JSON.stringify(data))
      }).catch(err => {
        console.log('error ; ', error)
      })
    }

    return {
      logout,
      alertMessage,
      userData,
      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
      },
    }
  },
}
</script>

<style lang='scss'>
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
