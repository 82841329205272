<template>
  <span>
    <span v-if="$store.state.app.currentCurrency==='THB'">฿</span>
    <span v-else-if="$store.state.app.currentCurrency==='USD'">$</span>
    <span v-else >₩</span>
  </span>
</template>

<script>
import {mdiCurrencyKrw, mdiCurrencyUsd} from '@mdi/js'

export default {
  props: {},
  components: {},
  setup(_, {root, emit}) {
    return {mdiCurrencyKrw, mdiCurrencyUsd}
  },

}
</script>

<style scoped>

</style>
