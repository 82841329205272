import {
    mdiHomeOutline,
    mdiAlertOutline,
    mdiStorefrontOutline,
    mdiPaperRollOutline,
    mdiFileDocumentOutline,
    mdiAccountCircleOutline,
    mdiTruckDeliveryOutline,
    mdiCogs
} from '@mdi/js'

export default [
    {
        icon: mdiHomeOutline,
        title: 'หน้าแรก',
        to: 'manager-main',
    },
    {
        icon: mdiAlertOutline,
        title: 'สินค้าใกล้หมด',
        to: 'manager-productlower',
    },
    {
        icon: mdiAccountCircleOutline,
        title: 'ข้อมูลลูกค้า',
        to: 'customer',
    },
    {
        title: 'Store',
        icon: mdiStorefrontOutline,
        children: [
            {
                title: 'Data center',
                to: 'manager-datacenter',
            },
            {
                title: 'คลังสินค้า',
                to: 'manager-store',
            },
            {
                title: 'ข้อมูลสินค้า',
                to: 'manager-product',
            },
        ],
    },
    {
        title: 'ออเดอร์',
        icon: mdiPaperRollOutline,
        children: [
            {
                title: 'สร้างออเดอร์',
                to: 'create-order',
            },
            {
                title: 'รายการสั่งซื้อ',
                to: 'order-list',
            },
        ],
    },
    {
        title: 'ขนส่ง',
        icon: mdiTruckDeliveryOutline,
        children: [
            {
                title: 'Tracking',
                to: 'manager-tracking',
            },
            {
                title: 'ข้อมูลรายการขนส่ง',
                to: 'manager-transport-list',
            },
            {
                title: 'ใบแปะหน้ากล่อง',
                to: 'cover-sheet',
            },
        ],
    },
    {
        title: 'รายงาน',
        icon: mdiFileDocumentOutline,
        children: [
            {
                title: 'รายงานยอดขาย',
                to: 'report-sale',
            },
            {
                title: 'รายงานสินค้าคงเหลือ',
                to: 'report-product',
            },
            {
                title: 'รายงายความเคลื่อนไหวสินค้า',
                to: 'report-product-movement',
            },
        ],
    },
    {
        icon: mdiCogs,
        title: 'ตั้งค่าร้านค้า',
        to: 'manager-setting',
    },
]
