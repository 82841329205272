import Vue from 'vue'
import VueRouter from 'vue-router'
import beforeEnter from '@/router/checkLogin'
import administrator from '@/router/administrator'
import center from './center'
import manager from './manager'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        // name: 'home-page',
        // component: () => import('@/views/homepage/Home.vue'),
        // meta: {
        //     layout: 'blank',
        // },
        redirect: '/login',
    },
    {
        path: '/tracking/:id',
        name: 'tracking',
        component: () => import('@/views/homepage/Tracking'),
        meta: {
            layout: 'blank',
        },
        // redirect: '/login',
    },
    {
        path: '/print',
        name: 'print',
        component: () => import('@/views/Print'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/print2',
        name: 'print2',
        component: () => import('@/views/Print2'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/print3',
        name: 'print3',
        component: () => import('@/views/Print3'),
        meta: {
            layout: 'blank',
        },
    },
    ...center,
    ...administrator,
    ...manager,
    {
        path: '/customers',
        name: 'customer',
        component: () => import('@/views/customers/Customer'),
        meta: {
            layout: 'content',
        },
        beforeEnter(to, from, next) {
            const token = localStorage.getItem('token')
            const userData = localStorage.getItem('userData')
            if (token && userData) {
                return next()
            }
            return next({ name: 'auth-login' })
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/Profile'),
        meta: {
            layout: 'content',
        },
        beforeEnter(to, from, next) {
            const token = localStorage.getItem('token')
            const userData = localStorage.getItem('userData')
            if (token && userData) {
                return next()
            }
            return next({ name: 'auth-login' })
        }
    },
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/Login.vue'),
        meta: {
            layout: 'blank',
        },
        beforeEnter: (to, from, next) => {
            const token = localStorage.getItem('token')
            const userData = localStorage.getItem('userData')
            const roleName = localStorage.getItem('userRole')
            if (token && userData) {
                if (roleName === 'admin') {
                    return next({ name: 'administrator-main' })
                } else if (roleName === 'center') {
                    return next({ name: 'center-product' })
                } else if (roleName === 'manager' || roleName === 'user') {
                    return next({ name: 'manager-main' })
                }
            }

            return next()
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/Error404.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 }
    },
})

export default router
