import beforeEnter from '@/router/checkLogin'

const administrator = [
    {
        path: '/administrator/main',
        name: 'administrator-main',
        component: () => import('@/views/administrator/Dashboard/Main'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/administrator/shop',
        name: 'administrator-shop',
        component: () => import('@/views/administrator/shop/Shop'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/administrator/users',
        name: 'administrator-users',
        component: () => import('@/views/administrator/users/User'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/administrator/promotions',
        name: 'administrator-promotions',
        component: () => import('@/views/administrator/promotions/Promotions'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/administrator/sliders',
        name: 'administrator-sliders',
        component: () => import('@/views/administrator/sliders/Sliders'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/administrator/report-sale',
        name: 'administrator-report-sale',
        component: () => import('@/views/administrator/reports/ReportSale'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/administrator/setting',
        name: 'administrator-setting',
        component: () => import('@/views/administrator/Setting'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
]
export default administrator
