import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create( {
    // baseURL: 'https://api.panuwat.online/',
    baseURL : 'https://api.bizme24.com/',
    //baseURL: 'http://192.168.1.113:8000/',
} )
Vue.prototype.$http = axiosIns
export default axiosIns
