import axios from '@axios'

const token = localStorage.getItem('token')
axios.defaults.headers.common.Authorization = token
const api = {
    async post(payload) {
        const { path, body } = payload
        const respone = await new Promise((resolve) => {
            axios
                .post(`${ path }`, body)
                .then(async (res) => {
                    if (res.data.code === 20000) {
                        localStorage.clear()
                        window.location.reload()
                    } else {
                        resolve(res.data.data == null ? { ...res.data, data: [] } : res.data)
                    }
                })
                .catch((err) => {
                    console.log('POST ERROR : ', err)
                    resolve('unAuthorized')
                })
        })
        return respone
    },

    async get(payload) {
        try {
            const { path, param } = payload
            const respone = await new Promise((resolve) => {
                const url = `${ path }${ param || param == 0 ? `/${ param }` : '' }`
                axios
                    .get(url)
                    .then(async (res) => {
                        if (res.data.code === 20000) {
                            localStorage.clear()
                            window.location.reload()
                        } else {
                            resolve(res.data)

                        }
                    })
                    .catch((err) => {
                        console.log('error : ', err)
                        resolve('unAuthorized')
                    })
            })

            return respone
        } catch (err) {
            console.log('GET RESPONSE ERROR : ', err)
            resolve('unAuthorized')
            return 'GET ERROR'
        }
    },

    async put(payload) {
        try {
            const { path, param, body } = payload
            const respone = await new Promise((resolve) => {
                const url = `${ path }${ param || param == 0 ? `/${ param }` : '' }`
                axios.put(url, body).then(async (res) => {
                    if (res.data.code === 20000) {
                        localStorage.clear()
                        window.location.reload()
                    } else {
                        resolve(res.data)
                    }
                })
            })

            return respone
        } catch (err) {
            console.log(err)

            return 'UPDATE ERROR'
        }
    },

    async delete(payload) {
        try {
            const { path, param } = payload
            const respone = await new Promise((resolve) => {
                const url = `${ path }${ param || param == 0 ? `/${ param }` : '' }`
                axios.delete(url).then(async (res) => {
                    if (res.data.code === 20000) {
                        localStorage.clear()
                        window.location.reload()
                    } else {
                        resolve(res.data)
                    }
                })
            })

            return respone
        } catch (err) {
            console.log(err)

            return 'DELETE ERROR'
        }
    },
}

export { api }
