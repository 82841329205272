import beforeEnter from '@/router/checkLoginManager'

const manager = [
    {
        path: '/manager/main',
        name: 'manager-main',
        component: () => import('@/views/manager/Main'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/manager/productlower',
        name: 'manager-productlower',
        component: () => import('@/views/manager/product/ProductLower'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/manager/datacenter',
        name: 'manager-datacenter',
        component: () => import('@/views/manager/datacenter/DataCenter'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/manager/store',
        name: 'manager-store',
        component: () => import('@/views/manager/store/Store.vue'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/manager/store/:id',
        name: 'manager-store-detail',
        component: () => import('@/views/manager/store/StoreDetail.vue'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/manager/product',
        name: 'manager-product',
        component: () => import('@/views/manager/product/Products'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/manager/setting',
        name: 'manager-setting',
        component: () => import('@/views/manager/settings/Setting'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/manager/order',
        name: 'create-order',
        component: () => import('@/views/manager/order/createOrder/CreateOrder'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/manager/editorder/:id',
        name: 'edit-order',
        component: () => import('@/views/manager/order/editOrder/EditOrder'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/manager/order-list',
        name: 'order-list',
        component: () => import('@/views/manager/order/orderList/OrderList'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/manager/order-confirm',
        name: 'order-confirm',
        component: () => import('@/views/manager/order/orderConfirm/OrderConfirm'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/manager/order-history',
        name: 'order-history',
        component: () => import('@/views/manager/order/orderHistory/OrderHistory'),
        meta: {
            layout: 'content',
        },
        beforeEnter,
    },
    {
        path: '/manager/order-print-th',
        name: 'order-print-th',
        component: () => import('@/components/prints/PrintOrderTh'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/manager/order-print-th-80',
        name: 'order-print-th-80',
        component: () => import('@/components/prints/PrintOrderTh80'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/manager/order-print-krw',
        name: 'order-print-krw',
        component: () => import('@/components/prints/PrintOrderKrw'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/manager/order-print-krw-80',
        name: 'order-print-krw-80',
        component: () => import('@/components/prints/PrintOrderKrw80'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/manager/vat-print-th',
        name: 'vat-print-th',
        component: () => import('@/components/prints/PrintOrderVatTh'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/manager/vat-print-krw',
        name: 'vat-print-krw',
        component: () => import('@/components/prints/PrintOrderVatKrw'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/manager/report-sale',
        name: 'report-sale',
        component: () => import('@/views/report/Sale'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/manager/report-product',
        name: 'report-product',
        component: () => import('@/views/report/ProductBalance'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/manager/report-product-movement',
        name: 'report-product-movement',
        component: () => import('@/views/report/ProductMovement'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/manager/transport-list',
        name: 'manager-transport-list',
        component: () => import('@/views/manager/transport/TransportList.vue'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/manager/cover-sheet',
        name: 'cover-sheet',
        component: () => import('@/views/manager/transport/CoverSheet'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/manager/tracking',
        name: 'manager-tracking',
        component: () => import('@/views/manager/transport/Tracking'),
        meta: {
            layout: 'content',
        },
    },
    {
        path: '/manager/cover-print-th',
        name: 'print-cover-th',
        component: () => import('@/views/manager/transport/CoverTh'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/manager/cover-print-ko',
        name: 'print-cover-ko',
        component: () => import('@/views/manager/transport/CoverKo'),
        meta: {
            layout: 'blank',
        },
    },
]
export default manager
