import adminMenu from './adminMenu'
import center from './center'
import manager from './manager'
import user from './user'
import {mdiAccountCircleOutline} from "@mdi/js";
import {api} from "@/services/api";



const roleName = localStorage.getItem('userRole')
export default [
    ...roleName === 'admin' ? adminMenu : roleName === 'center' ? center : roleName === 'user' ? user : manager
]
