import {mdiAccountOutline, mdiCubeOutline, mdiAnimationOutline, mdiReceipt, mdiAccountCircleOutline} from '@mdi/js'

export default [
  {
    icon: mdiCubeOutline,
    title: 'สินค้า',
    to: 'center-product',
  },
  {
    icon: mdiAccountCircleOutline,
    title: 'ข้อมูลลูกค้า',
    to: 'customer',
  },
  {
    icon: mdiAnimationOutline,
    title: 'หมวดหมู่สินค้า',
    to: 'center-category',
  },
  {
    icon: mdiReceipt,
    title: 'ภาษีมูลค่าเพิ่ม',
    to: 'center-vat',
  },
]
