function beforeEnter(to, from, next) {
    const token = localStorage.getItem('token')
    const userData = localStorage.getItem('userData')
    const roleName = localStorage.getItem('userRole')
    const linkList = JSON.parse(localStorage.getItem('menu')) || []
    if (token && userData) {
        if (roleName === 'manager') {
            return next()
        } else if (roleName === 'user') {
            const findPath = linkList.find(item => item === to.name)
            if (!findPath) {
                return next({name: 'error-404'})
            } else {
                return next()
            }
        } else {
            return next({name: 'error-404'})
        }
    }
    return next({name: 'auth-login'})
}

export default beforeEnter
