<template>
    <v-menu
            offset-y
            nudge-bottom="22"
            min-width="175"
            left
            :elevation="$vuetify.theme.dark ? 9 : 8"
    >
        <!-- Activator -->
        <template v-slot:activator="{ on, attrs }">
            <div
                    v-bind="attrs"
                    class="d-flex align-center"
                    v-on="on"
            >
                สกุลเงิน :
                <CurrentCurrency class="mx-1"/>
                {{ currency.currency_code }}

            </div>
        </template>

        <!-- Options List -->
        <v-list>
            <v-list-item-group>
                <v-list-item v-for="item in currencies" @click="currency = item;updateActiveCurrency()">
                    <v-list-item-title>{{ item.currency_code }} ({{ item.currency_text }})</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
        </v-list>
    </v-menu>
</template>

<script>
import { loadLanguageAsync } from '@/plugins/i18n'
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref, onMounted } from "@vue/composition-api";
import { api } from "@/services/api";
import { mdiCurrencyUsd } from "@mdi/js";
import store from "@/store"
import CurrentCurrency from "@/components/CurrentCurrency";

export default {
    components: { CurrentCurrency },
    setup() {

        const currencies = ref([])
        const currency = ref({})

        onMounted(() => {
            getCurrenCies()
        })


        const getCurrenCies = async () => {
            api.get({
                path: '/currencys',
                param: `?search=&active=1`,
            }).then(res => {
                currencies.value = res.data
                if (localStorage.getItem('currency') && localStorage.getItem('currencyId')) {
                    currency.value.currency_code = localStorage.getItem('currency')
                    currency.value.currency_id = localStorage.getItem('currencyId')
                    store.commit('app/setCurrentCurrencyId', localStorage.getItem('currencyId'))
                    store.commit('app/setCurrentCurrency', localStorage.getItem('currency'))
                } else {
                    localStorage.setItem('currency', res.data[0].currency_code)
                    localStorage.setItem('currencyId', res.data[0].currency_id)
                    store.commit('app/setCurrentCurrency', res.data[0].currency_code)
                    store.commit('app/setCurrentCurrencyId', res.data[0].currency_id)
                    currency.value.currency_id = res.data[0].currency_id
                    currency.value.currency_code = res.data[0].currency_code
                }
            }).catch(err => {
                console.log(err)
            })
        }
        const { isRtl } = useAppConfig()

        const locales = []

        const updateActiveCurrency = () => {
            localStorage.setItem('currency', currency.value.currency_code)
            localStorage.setItem('currencyId', currency.value.currency_id)
            store.commit('app/setCurrentCurrency', currency.value.currency_code)
            store.commit('app/setCurrentCurrencyId', currency.value.currency_id)
            window.location.reload()
        }

        return {
            locales,
            currencies,
            currency,
            mdiCurrencyUsd,
            updateActiveCurrency,
        }
    },
}
</script>
