export default {
    namespaced: true,
    state: {
        shallContentShowOverlay: false,
        isShow: false,
        customerCreateOrder: '',
        roleName: '',
        currentCurrency: '',
        currentCurrencyId: '',
    },
    getters: {},
    mutations: {
        setCurrentCurrency(state, value) {
            state.currentCurrency = value
        },
        setCurrentCurrencyId(state, value) {
            state.currentCurrencyId = value
        },
        setCustomerCreateOrder(state, customer) {
            state.customerCreateOrder = customer
        },
        setRoleName(state, roleName) {
            state.roleName = roleName
        },
        ALERT(state, {message, color}) {
            state.color = color
            state.message = message
            state.isShow = !state.isShow
        },
        TOGGLE_CONTENT_OVERLAY(state, value) {
            state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
        },
    },
    actions: {},
}
