<template>
    <layout-content-vertical-nav :nav-menu-items="dataList">
        <slot></slot>

        <!-- Slot: Navbar -->
        <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
            <div
                    class="navbar-content-container"
                    :class="{'expanded-search': shallShowFullSearch}"
            >
                <!-- Left Content: Search -->
                <div class="d-flex align-center">
                    <v-icon
                            v-if="$vuetify.breakpoint.mdAndDown"
                            class="me-3"
                            @click="toggleVerticalNavMenuActive"
                    >
                        {{ icons.mdiMenu }}
                    </v-icon>
                    <v-btn
                            v-if="userData.role_name === 'manager'"
                            depressed
                            rounded
                            :to="{ name: 'create-order' }"
                            large
                            :color="'transparent'"
                            class='horizontal-nav-menu-header-link px-6 d-none d-md-inline pt-2 me-2'
                            active-class='bg-gradient-primary header-link-active font-active white--text'
                    >
                        <v-icon class='me-1'>
                            {{ icons.mdiReceipt }}
                        </v-icon>
                        <span>
           สร้างออเดอร์
         </span>
                    </v-btn>
                    <!--          <v-btn
                                  depressed
                                  rounded
                                  :to="{ name: 'order-list', query: { status: '1' } }"
                                  large
                                  :color="'transparent'"
                                  class='horizontal-nav-menu-header-link px-6 d-none d-md-inline pt-2 me-2'
                                  active-class='bg-gradient-primary header-link-active font-active white&#45;&#45;text'
                              >
                                <v-icon class='me-1'>
                                  {{ icons.mdiReceipt }}
                                </v-icon>
                                ออเดอร์รอชำระ
                                <v-badge color='error' inline :content='10'></v-badge>
                              </v-btn>
                              <v-btn
                                  depressed
                                  rounded
                                  :to="{ name: 'order-list', query: { status: '1' } }"
                                  large
                                  :color="'transparent'"
                                  class='horizontal-nav-menu-header-link px-6 d-none d-md-inline pt-2 me-2'
                                  active-class='bg-gradient-primary header-link-active font-active white&#45;&#45;text'
                              >
                                <v-icon class='me-1'>
                                  {{ icons.mdiReceipt }}
                                </v-icon>
                                ออเดอร์รอคอนเฟิร์ม
                                <v-badge color='error' inline :content='3'></v-badge>
                              </v-btn>
                              <v-btn
                                  depressed
                                  rounded
                                  :to="{ name: 'order-list', query: { status: '1' } }"
                                  large
                                  :color="'transparent'"
                                  class='horizontal-nav-menu-header-link px-6 d-none d-md-inline pt-2 me-2'
                                  active-class='bg-gradient-primary header-link-active font-active white&#45;&#45;text'
                              >
                                <v-icon class='me-1'>
                                  {{ icons.mdiReceipt }}
                                </v-icon>
                                ออเดอร์รอจัดส่ง
                                <v-badge color='error' inline :content='10'></v-badge>
                              </v-btn>-->
                </div>
                <div>
                    ร้าน : {{ shop.shop_name }}
                </div>
                <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
                <div class="d-flex align-center right-row">
                    <AppBarCurrency/>
                    <app-bar-theme-switcher class='mx-4'></app-bar-theme-switcher>
                    <app-bar-user-menu></app-bar-user-menu>
                </div>
            </div>
        </template>

        <!-- Slot: Footer -->
        <template #footer>
            <div class="d-flex justify-space-between">
        <span>COPYRIGHT &copy; {{ new Date().getFullYear() }} <span
                class="text-decoration-none"
        >Online store management</span><span class="d-none d-md-inline">, All rights Reserved</span></span>
            </div>
        </template>
    </layout-content-vertical-nav>
</template>

<script>
import LayoutContentVerticalNav from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import navMenuItems from '@/navigation/vertical'

// App Bar Components
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue'
import AppBarUserMenu from '@/components/AppBarUserMenu.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'
import { mdiMenu, mdiHeartOutline, mdiReceipt } from '@mdi/js'

import { getVuetify } from '@core/utils'

// Search Data
import appBarSearchData from '@/assets/app-bar-search-data'
import { ref, watch, onMounted } from '@vue/composition-api'
import AppBarCurrency from "@core/layouts/components/app-bar/AppBarCurrency";
import { api } from "@/services/api"
import user from "@/navigation/vertical/user";

export default {
    components: {
        AppBarCurrency,
        LayoutContentVerticalNav,
        AppBarThemeSwitcher,
        AppBarSearch,
        AppBarUserMenu,
    },
    setup() {
        const dataList = ref(navMenuItems)
        const shop = ref({})
        onMounted(() => {
            setTimeout(() => {
                shop.value = JSON.parse(localStorage.getItem('shop'))
            }, 2000)

            if (localStorage.getItem('userRole') === 'user') {
                dataList.value = []
                api.get({
                    path: '/menuShow',
                }).then(({ data, path }) => {
                    user.forEach(item => {
                        data.forEach(data => {
                            if (item.title === data) {
                                dataList.value.push(item)
                            }
                        })
                    })
                    localStorage.setItem('menu', JSON.stringify(path))
                }).catch(error => {
                    console.log(error)
                })
            }
        })

        const $vuetify = getVuetify()

        const userData = ref({})

        userData.value = JSON.parse(localStorage.getItem('userData'))


        // Search
        const appBarSearchQuery = ref('')
        const shallShowFullSearch = ref(false)
        const maxItemsInGroup = 5
        const totalItemsInGroup = ref({
            pages: 0,
            files: 0,
            contacts: 0,
        })
        watch(appBarSearchQuery, () => {
            totalItemsInGroup.value = {
                pages: 0,
                files: 0,
                contacts: 0,
            }
        })

        // NOTE: Update search function according to your usage
        const searchFilterFunc = (item, queryText, itemText) => {
            if (item.header || item.divider) return true

            const itemGroup = (() => {
                if (item.to !== undefined) return 'pages'
                if (item.size !== undefined) return 'files'
                if (item.email !== undefined) return 'contacts'

                return null
            })()

            const isMatched = itemText.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1

            if (isMatched) {
                if (itemGroup === 'pages') totalItemsInGroup.value.pages += 1
                else if (itemGroup === 'files') totalItemsInGroup.value.files += 1
                else if (itemGroup === 'contacts') totalItemsInGroup.value.contacts += 1
            }

            return appBarSearchQuery.value && isMatched && totalItemsInGroup.value[itemGroup] <= maxItemsInGroup
        }

        // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlaty
        const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) => {
            if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive) {
                toggleVerticalNavMenuActive()
            }
        }

        return {
            dataList,
            handleShallShowFullSearchUpdate,
            shop,

            // Search
            appBarSearchQuery,
            shallShowFullSearch,
            appBarSearchData,
            searchFilterFunc,
            userData,
            icons: {
                mdiMenu,
                mdiReceipt,
                mdiHeartOutline,
            },
        }
    },
}
</script>

<style lang="scss" scoped>
.navbar-content-container {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
}

// ? Handle bg of autocomplete for blured appBar
.v-app-bar.bg-blur {
  .expanded-search {
    ::v-deep .app-bar-autocomplete-box div[role='combobox'] {
      background-color: transparent;
    }

    > .d-flex > button.v-icon {
      display: none;
    }

    // ===

    & > .right-row {
      visibility: hidden;
      opacity: 0;
    }

    ::v-deep .app-bar-search-toggler {
      visibility: hidden;
    }
  }
}
</style>
